// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feature {
    position: relative;
    /* padding-top: 24px; */
    padding-left: 24px;
  }
  
  .overlay {
    position: absolute;
    height: 100px;
    width: 160px;
    top: 0;
    left: 0;
    background-color: #F3D9F7;
    z-index: 1;
  }
  
  .content {
    position: relative;
    z-index: 2;
  }
  
  .icon {
    color: black;
  }
  
  .title {
    color: #000;
  }
  `, "",{"version":3,"sources":["webpack://./src/auth-model/landingPage/Step.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,MAAM;IACN,OAAO;IACP,yBAAyB;IACzB,UAAU;EACZ;;EAEA;IACE,kBAAkB;IAClB,UAAU;EACZ;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,WAAW;EACb","sourcesContent":[".feature {\n    position: relative;\n    /* padding-top: 24px; */\n    padding-left: 24px;\n  }\n  \n  .overlay {\n    position: absolute;\n    height: 100px;\n    width: 160px;\n    top: 0;\n    left: 0;\n    background-color: #F3D9F7;\n    z-index: 1;\n  }\n  \n  .content {\n    position: relative;\n    z-index: 2;\n  }\n  \n  .icon {\n    color: black;\n  }\n  \n  .title {\n    color: #000;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
