import React from 'react';
import { Row, Col, Typography, Grid } from 'antd';
import { IconHome, IconCertificate, IconCheck } from '@tabler/icons-react';
import Step from './step';
import './ThreeStep.css';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const mockdata = [
  {
    icon: IconHome,
    title: 'Register',
    description: 'Start with providing your business details',
  },
  {
    icon: IconCertificate,
    title: 'Set Up',
    description: 'Then upload all the required documents',
  },
  {
    icon: IconCheck,
    title: 'On board',
    description: 'Now team will review them and get back to you with any further instructions or updates',
  },
];

const ThreeStep: React.FC = () => {
  const screens = useBreakpoint();

  return (
    <div className="three-step-container">
      <Title level={2} className="three-step-title">
        Our 3 Step Approval Process
      </Title>
      <Row gutter={[50, 50]} justify="center">
        {mockdata.map((item) => (
          <Col key={item.title} xs={24} sm={12} md={8}>
            <Step {...item} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ThreeStep;
