import React from 'react'
import { Spin } from 'antd'
import '../../App.css'

const Loading = () => {
  return (
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    }}>
      <Spin size="large" className="custom-spinner"/>
    </div>
  )
}

export default Loading
