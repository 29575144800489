// src/pushManager.ts
import { message } from 'antd';
import {authStore} from '../src/services'

const PUBLIC_VAPID_KEY = 'BDxJ-ltAqGJLK89joXT39vJLaoRR7wmJbB6dYGMF4vjXzqOF_Oje9O-c4WlFqJhUQh58fzT2eVj1BkVjx3GUYDo'; // Your VAPID public key here

// Helper to convert the VAPID public key
const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

// Register the service worker and subscribe the user to push notifications
export const registerPushSubscription = async (): Promise<void> => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.ready;

      // Request push subscription with VAPID key
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(PUBLIC_VAPID_KEY),
      });
          
      const userID = localStorage.getItem("userId");
        
      let object:any = {}
         object.subscription = subscription
         object.userID = userID

         console.log("keys",object)
      const response = await authStore(subscription);
      try {
        const partnerID = response.data.data.partnerID;
        // localStorage.setItem("partnerID", partnerID);
     
      } catch (error: any) {
        message.error(error.message);
      }
      // You can send this subscription to your backend to store it for later use.

    } catch (error) {
      console.error('Failed to subscribe to push notifications:', error);
    }
  } else {
    console.log('Service Workers are not supported in this browser.');
  }
};
