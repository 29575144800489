import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap');

  body,
  .ant-typography,
  .ant-btn,
  .ant-input,
  .ant-select,
  .ant-modal-content,
  .ant-table,
  .ant-card,
  .ant-form {
    font-family: "Lexend Deca", sans-serif !important;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 400; // Lighter weight for general text
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .ant-btn {
    font-weight: 400; // Slightly heavier than body text, but still light
  }

  .ant-typography {
    h1 {
      font-weight: 500; // Reduced weight for headings
    }
    h2, h3, h4, h5, h6 {
      font-weight: 400; // Even lighter for subheadings
    }
  }

  // Adjust specific component fonts if needed
  .ant-input, .ant-select {
    font-weight: 300;
  }

  .ant-table {
    font-weight: 300;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-weight: 300;
  }
`;

export default GlobalStyle;