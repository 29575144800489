import React from "react";
import { Layout,Row, Col, Typography, Button, List, Space } from "antd";
import { CheckCircleOutlined  } from "@ant-design/icons";
import bannerImage from "../../assets/Anvayaa.svg";
import { useNavigate } from "react-router-dom";
import ThreeStep from "./ThreeStep";
import CustomHeader from "./landingHeader";


const { Title, Text } = Typography;
const { Footer, Content } = Layout;

const HomePage = () => {
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate("/partnerlogin");
  };
  const handleJoinUs = () => {
    navigate("/partnerRegistration");
  };

  return (
    <Layout style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
    <CustomHeader  />
    <Content style={{ padding: '40px', paddingBottom: '0px', flex: 1 }}>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <div style={{ maxWidth: 600 }}>
            <Title style={{ fontSize: '35px', fontWeight: 500 }}>
              Welcome to{" "}
              <span
                style={{
                  backgroundColor: '#F7DCD9',
                  borderRadius: '4px', 
                  padding: '1px 10px',
                }}
              >
                Anvayaa's
              </span>{" "}
              Partner Management System
            </Title>
            <Text
              type="secondary"
              style={{ display: 'block', marginTop: '10px' }}
            >
              Crafting a new way to manage your business.
            </Text>
            <List
              style={{ marginTop: '10px' }}
              dataSource={[
                {
                  title: 'Track business',
                  description:
                    'Track sales, customers, and growth levers directly from your phone',
                },
                {
                  title: 'Durability',
                  description:
                    'We enable you to get more revenue, new customers, and boost your brand visibility by providing insights to improve your business.',
                },
                {
                  title: '24*7 Assistance',
                  description:
                    'Get expert assistance for all your doubts with our partner support program',
                },
              ]}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <CheckCircleOutlined
                        style={{
                          fontSize: '25px',
                          color: 'white',
                          backgroundColor: 'purple',
                          borderRadius: '20px',
                        }}
                      />
                    }
                    title={<b>{item.title}</b>}
                    description={item.description}
                  />
                </List.Item>
              )}
            />
            <Space style={{ marginTop: '30px' }}>
              <Button
                type="primary"
                shape="round"
                size="large"
                className="button-color"
                style={{ backgroundColor: '#F79122' }}
                onClick={handleJoinUs}
              >
                Join Us
              </Button>
              <Button
                shape="round"
                size="large"
                style={{ backgroundColor: '#914572', color: 'white' }}
                onClick={handleLogin}
              >
                Sign In
              </Button>
            </Space>
          </div>
        </Col>
        <Col
          xs={24}
          md={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            src={bannerImage}
            alt="Banner"
            style={{ maxWidth: '35%', height: '30%', maxHeight: '250px' }}
          />
          <ThreeStep />
         
        </Col>
      </Row>
    </Content>
    <Footer style={{ textAlign: 'center', backgroundColor: 'white', padding: '5px', position: 'sticky', bottom: 0, zIndex: 1, width: '100%' }}>
      ©{new Date().getFullYear()} Anvayaa Kincare Pvt. Ltd. All Rights Reserved
    </Footer>
  </Layout>
    
  );
};

export default HomePage;
