import React, { useEffect } from 'react';
import AdminRoutes from './routing/masterrouting';
import './App.css';
import PartnerRoutes from './routing/masterrouting';
import GlobalStyle from './GlobalStyle';
import { registerPushSubscription } from './pushManager';
function App() {
  useEffect(() => {
    registerPushSubscription(); // Register push notifications when the component mounts
  }, []);
  return (
    <>
      <GlobalStyle />
      <PartnerRoutes />
    </>


  );
}

export default App;
