import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import HomePage from '../auth-model/landingPage/landingPage';
import Loading from '../components/common form/Loading';

// Lazy Loading Components for PARTNER
const LazyViewContract = React.lazy(() => import('../components/partnerscreens/contracts/contracts'));
const LazyPartnerStaff = React.lazy(() => import('../components/partnerscreens/staffmanagement/partnerstaff'))
const LazyAmbulance = React.lazy(() => import('../components/partnerscreens/ambulance/partnerambulance'))
 const LazyBankDetails = React.lazy(() => import('../components/partnerscreens/onboarding/bankdetails'))
const LazyPartnerLogin = React.lazy(() => import('../auth-model/login/partnerlogin'))
const LazyRegisterPage = React.lazy(() => import('../auth-model/registerpage'))
const LazyMainLayout = React.lazy(() => import('../components/partnerscreens/partnerlayout'))
const LazyDashboard = React.lazy(() => import('../components/partnerscreens/dashboard/dashboard'))
const LazyAdminLayout = React.lazy(() => import('../components/adminscreens/adminlayout'))
const LazyJoinUs = React.lazy(() => import('../components/partnerscreens/onboarding/joinus'))
const LazyPartnerPendingBills = React.lazy(() => import('../components/partnerscreens/dashboard/partnerpendingbills'))
const LazyDisputeBills = React.lazy(() => import('../components/partnerscreens/dashboard/disputebills'));
const LazyJobrequests = React.lazy(() => import('../components/partnerscreens/dashboard/jobrequests'));
const LazyPendingInvoice = React.lazy(() => import('../components/partnerscreens/invoice/pendinginvoice'));
 const LazyFinance=React.lazy(()=>import('../components/partnerscreens/Finance/finance'))
const LazyAcceptedRequest=React.lazy(()=>import('../components/partnerscreens/dashboard/AcceptedRequests/acceptedrequest'))
const LazyPartnerAgreement = React.lazy(() => import('../components/partnerscreens/contracts/partnerAgreement')); 
const  LazyProfileCard =React.lazy(()=>import('../components/partnerscreens/ProfileData/profile'))
const  ContractTable =React.lazy(() => import('../components/partnerscreens/contracts/contracttable'))
const LazyForgetPasswoard = React.lazy(()=>import ('../auth-model/login/forgetpasswoard'))
const LazyRequestsReports = React.lazy(()=>import('../components/partnerscreens/requestsreports'))
const LazyApprovedContract = React.lazy(()=>import('../components/partnerscreens/contracts/approvedcontract'))
// const LazyProfileViewOnly = React.lazy(() => import('../components/partnerscreens/ProfileData/profile'))

const LazyChangePasswoard = React.lazy(()=>import('../auth-model/login/changepasswoard'))


// Lazy Loading Components for ADMIN
const LazyAdminLogin = React.lazy(() => import('../auth-model/login/adminlogin'))
const LazyAdminDashboard = React.lazy(() => import('../components/adminscreens/dashboardComponents/admindasboards'))
const LazyAdminContracts = React.lazy(() => import('../components/adminscreens/contractComponents/AdminContracts'))
const LazyAdminViewPartner = React.lazy(() => import('../components/adminscreens/contractComponents/AdminViewPartner'))
const LazyAdminPartners = React.lazy(() => import('../components/adminscreens/contractComponents/AdminPartners'));
const LazyAdminViewContract = React.lazy(() => import('../components/adminscreens/contractComponents/AdminViewContract'));
const LazyCreateContract = React.lazy(() => import('../components/adminscreens/contractComponents/CreateContract'));
const LazyViewAgreement = React.lazy(() => import('../components/adminscreens/contractComponents/Agreement'));
const LazyAdminStaff = React.lazy(() => import('../components/adminscreens/staffmanagement/partnerstaff'));
const LazyMSPTable =React.lazy(()=> import('../components/adminscreens/contractComponents/msptable'));
const LazyPartnerContractsHistory = React.lazy(()=> import('../components/adminscreens/contractComponents/contractshistory'))

const PartnerRoutes: React.FC = () => {
  const [loginType, setLoginType] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const storedLogin = localStorage.getItem('Login-Type');
    const token = localStorage.getItem('token');

    if (token) {
      if (storedLogin === 'Partner' || storedLogin === 'Admin') {
        setLoginType(storedLogin);
      } else {
        setLoginType(null);
        navigate('/partnerlogin');
      }
    } else {
      setLoginType(null);
    }

    setIsLoading(false); // Update loading state
  }, [navigate]);

  if (isLoading) {
    return <div><Loading /></div>; // Show loading state while checking token
  }

  return (
    <Routes>
      {!loginType ? (
        <>
          <Route path="/partnerlogin" element={<React.Suspense fallback={<Loading />}><LazyPartnerLogin /></React.Suspense>} />
          <Route path="/admin" element={<React.Suspense fallback={<Loading />}><LazyAdminLogin /></React.Suspense>} />
          <Route path="/partnerRegistration" element={<React.Suspense fallback={<Loading />}><LazyRegisterPage /></React.Suspense>} />
          <Route path="/forgetpasswoard" element={<React.Suspense fallback={<Loading />}><LazyForgetPasswoard /></React.Suspense>} />
          <Route path="/home" element={<HomePage />} />
          

          <Route path="*" element={<Navigate to="/home" />} />

        </>
      ) : loginType === 'Partner' ? (
        <Route>
        {/* Route without layout */}
        <Route path="printcontract" element={<React.Suspense fallback={<Loading />}><LazyPartnerAgreement /></React.Suspense>} />
        {/* Routes with layout */}
        <Route path="/*" element={<React.Suspense fallback={<Loading />}><LazyMainLayout /></React.Suspense>}>
          {/* <Route index element={<React.Suspense fallback={<Loading />}><LazyDashboard /></React.Suspense>} /> */}
          <Route path="dashboard" element={<React.Suspense fallback={<Loading />}><LazyDashboard /></React.Suspense>} />
          <Route path="contracts" element={<React.Suspense fallback={<Loading />}><ContractTable /></React.Suspense>} />
          <Route path="staff" element={<React.Suspense fallback={<Loading />}><LazyPartnerStaff /></React.Suspense>} />
          <Route path="joinus" element={<React.Suspense fallback={<Loading />}><LazyJoinUs /></React.Suspense>} />
          <Route path="ambulance" element={<React.Suspense fallback={<Loading />}><LazyAmbulance /></React.Suspense>} /> 
          <Route path="bankdetails" element={<React.Suspense fallback={<Loading />}><LazyBankDetails /></React.Suspense>} />
          <Route path="partnerbills" element={<React.Suspense fallback={<Loading />}><LazyPartnerPendingBills /></React.Suspense>} />
          <Route path="disputebills" element={<React.Suspense fallback={<Loading />}><LazyDisputeBills /></React.Suspense>} />
          <Route path="jobs" element={<React.Suspense fallback={<Loading />}><LazyJobrequests /></React.Suspense>} />
          <Route path="pendinginvoice" element={<React.Suspense fallback={<Loading />}><LazyPendingInvoice /></React.Suspense>} />
          <Route path="finance" element={<React.Suspense fallback={<Loading />}><LazyFinance /></React.Suspense>} />
          <Route path="viewcontract" element={<React.Suspense fallback={<Loading />}><LazyViewContract /></React.Suspense>} />
          <Route path="acceptedrequest" element={<React.Suspense fallback={<Loading />}><LazyAcceptedRequest /></React.Suspense>} />
          <Route path="profile" element={<React.Suspense fallback={<Loading />}><LazyProfileCard /></React.Suspense>} />
          <Route path="changepasswoard" element={<React.Suspense fallback={<Loading />}><LazyChangePasswoard /></React.Suspense>} />
          <Route path="Requests" element={<React.Suspense fallback={<Loading />}><LazyRequestsReports /></React.Suspense>} />
          <Route path="ApprovedContract" element={<React.Suspense fallback={<Loading />}><LazyApprovedContract /></React.Suspense>} />
          <Route path="*" element={<Navigate to="dashboard" />} />
        </Route>
      </Route>
      
      ) : loginType === 'Admin' ? (
        <Route>
          <Route path="/agreement" element={<React.Suspense fallback={<Loading />}><LazyViewAgreement /></React.Suspense>} />
          <Route path="/*" element={<React.Suspense fallback={<Loading />}><LazyAdminLayout /></React.Suspense>}>
            <Route path="admindashboard" element={<React.Suspense fallback={<Loading />}><LazyAdminDashboard /></React.Suspense>} />
            <Route path="adminviewpartner" element={<React.Suspense fallback={<Loading />}><LazyAdminViewPartner /></React.Suspense>} />
            <Route path="adminpartners" element={<React.Suspense fallback={<Loading />}><LazyAdminPartners /></React.Suspense>} />
            <Route path="admincontracts" element={<React.Suspense fallback={<Loading />}><LazyAdminContracts /></React.Suspense>} />
            <Route path="adminviewcontract" element={<React.Suspense fallback={<Loading />}><LazyAdminViewContract /></React.Suspense>} />
            <Route path="createcontract" element={<React.Suspense fallback={<Loading />}><LazyCreateContract /></React.Suspense>} />
            <Route path="staff" element={<React.Suspense fallback={<Loading />}><LazyAdminStaff /></React.Suspense>} />
            {/* <Route path="staff" element={<React.Suspense fallback={<Loading />}><LazyPartnerStaff /></React.Suspense>} /> */}
            <Route path='msp'element={<React.Suspense fallback={<Loading/>}><LazyMSPTable/></React.Suspense>}/>
            <Route path="changepasswoard" element={<React.Suspense fallback={<Loading />}><LazyChangePasswoard /></React.Suspense>} />
            <Route path="contracthistory" element={<React.Suspense fallback={<Loading />}><LazyPartnerContractsHistory /></React.Suspense>} />
            <Route path="*" element={<Navigate to="admindashboard" />} />
          </Route>
      </Route>
        
      ) : (
        <>
          <Route path="/partnerlogin" element={<React.Suspense fallback={<Loading />}><LazyPartnerLogin /></React.Suspense>} />
          <Route path="/admin" element={<React.Suspense fallback={<Loading />}><LazyAdminLogin /></React.Suspense>} />
          <Route path="/partnerRegistration" element={<React.Suspense fallback={<Loading />}><LazyRegisterPage /></React.Suspense>} />
          <Route path="/home" element={<HomePage />} />
        </>
      )}
    </Routes>
  );
};

export default PartnerRoutes;
