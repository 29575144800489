import { Alert, message } from 'antd';
import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { promises } from 'fs';
import { useNavigate } from 'react-router';

// Function to get the token from localStorage
// const getToken = (): string | null => {
//   const token = localStorage.getItem('token');
//   return token;
// };
export const getToken = (): string | null => {
  const token = localStorage.getItem('token');
  return token;
};

// Set up the Axios instance
const apiClient = axios.create({
    //  baseURL: 'http://localhost:3322',  
   baseURL: "https://partners.anvayaa.com:3333"
});




apiClient.interceptors.request.use(
  config => {
    const token = getToken();
    if (token) {
      if (!config.headers) {
        config.headers = {} as AxiosRequestHeaders;
      }
      config.headers['token'] = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);


export const login = async (phoneNo: string): Promise<{ userOtpID: string, token?: string , userId: string }>  => {
  try {
    const response = await apiClient.post('/partner/registeruser', {
      mobileNumber: phoneNo,
      type: 'SignIn',
    });
    if (response.status === 200 && response.data.data.userOtpID) {
      const otpUserId = response.data.data.userOtpID;
      const userId = response.data.data.userID; // Corrected variable name
      const token = response.data.data.token;
      // alert(response.data.message);
      message.success(response.data.message)
      
      if (token) {
        localStorage.setItem('token', token);
      }
      if (userId) {
        localStorage.setItem('userId', userId); // Storing userId in localStorage
      }
      return { userOtpID: otpUserId, token, userId }; // Corrected variable name in return
    } else if (response.status === 202) {
      message.info(response.data.message);
      return { userOtpID: '', token: undefined, userId: '' };
    } else {
      message.error(response.data.message || 'Invalid response from server');
      throw new Error('Invalid response from server');
    }
  } catch (error: any) {
    throw error;
  }
};


export const verifyOtp = async (OTP: number, userOtpID: string): Promise<AxiosResponse<any>> => {
  return await apiClient.post('/partner/verifyotp', {
    OTP,
    userOtpID,
  });
};

export const register = async (phoneNo: string, partnerName: string): Promise<{ userOtpID: string, token?: string,response?:number }> => {
  try {
    const response = await apiClient.post('/partner/registeruser', {
      mobileNumber: phoneNo,
      type: 'Joinus',
      partnerName: partnerName,
    });

    if (response.status === 200 && response.data.data.userOtpID) {
      const otpUserId = response.data.data.userOtpID;
      const token = response.data.data.token;
      // alert(response.data.message);
      message.success(response.data.message)
      // Store the token in localStorage
      if (token) {
        localStorage.setItem('token', token);
        localStorage.setItem('userId', response.data.data.userID);
      }
      return { userOtpID: otpUserId, token };
    } else if (response.status === 202) {
      
      message.info(response.data.message);
    
      return { userOtpID: '', token: undefined,"response":response.status};
    } else {
      message.error(response.data.message || 'Invalid response from server');
      throw new Error('Invalid response from server');
    }
  } catch (error: any) {
    throw error;
  }
};

export const registerUser = async (bills: any): Promise<AxiosResponse<any>> => {
  return await apiClient.post('/partner/partnerRequest/viewPartnerPayments', bills);
};

export const getLanguages = async (token: string): Promise<AxiosResponse<any>> => {
  try {
      const response = await apiClient.get('/partner/partnerProfile/getLanguages', {
          headers: {
              'token': token 
          }
      });
      return response;
  } catch (error: any) {
      console.error('Error fetching languages:', error.message);
      throw error;
  }
};
export const getAllServices = async (token: string): Promise<AxiosResponse<any>> => {
  try {
      const response = await apiClient.get('/partner/partnerProfile/getAllServices', {
          headers: {
              'token': token 
          }
      });
      return response;
  } catch (error: any) {
      console.error('Error fetching languages:', error.message);
      throw error;
  }
};
export const getAllServicesAreas = async (token: string): Promise<AxiosResponse<any>> => {
  try {
      const response = await apiClient.get('/partner/partnerProfile/getAllServicesAreas', {
          headers: {
              'token': token 
          }
      });
      return response;
  } catch (error: any) {
      console.error('Error fetching languages:', error.message);
      throw error;
  }
};
export const getAmbulanceTypes = async (token: string): Promise<AxiosResponse<any>> => {
  try {
      const response = await apiClient.get('/partner/partnerProfile/getAmbulanceTypes', {
          headers: {
              'token': token 
          }
      });
      return response;
  } catch (error: any) {
      console.error('Error fetching languages:', error.message);
      throw error;
  }
};

export const getServicesTypes = async (token: string): Promise<AxiosResponse<any>> => {
  try {
      const response = await apiClient.get('/partner/partnerProfile/getServicesTypes', {
          headers: {
              'token': token 
          }
      });
      return response;
  } catch (error: any) {
      console.error('Error fetching languages:', error.message);
      throw error;
  }
};


export const getPartnerProfile = async (): Promise<AxiosResponse<any>> => {
  try {
    const response = await apiClient.get('/partner/partnerProfile/viewPartnerProfile');
    return response;
  } catch (error: any) {
    console.error('Error fetching partner profile:', error.message);
    throw error;
  }
};

export const getPartnerProfileAdmin = async (partnerID: string): Promise<AxiosResponse<any>> => {
  try {
    const response = await apiClient.get('/partner/partnerProfile/viewPartnerProfile', {
      params: { partnerID }
    });
    return response;
  } catch (error: any) {
    console.error('Error fetching partner profile:', error.message);
    throw error;
  }
};




export const createPartnerServices = async (selectservices: any): Promise<AxiosResponse<any>> => {
  return await apiClient.post('/partner/partnerProfile/createPartnerServices', selectservices);
};

export const createPartnerServicesDetails = async (data: any): Promise<AxiosResponse<any>> => {
  return await apiClient.post('partner/partnerProfile/createPartnerServicesDetails', data);
};

export const creatBnakDetails = async (bankdetails: any): Promise<AxiosResponse<any>> => {
  return await apiClient.post('partner/bankdetails/createbankdetails', bankdetails);
};

export const cityNames = async (): Promise<AxiosResponse<any>> => {
  return await apiClient.get('/partner/partnerProfile/getAllServicesAreas');
};
export const Languages = async (): Promise<AxiosResponse<any>> => {
 return await apiClient.get('/partner/partnerProfile/getLanguages');
};
          
export const allServices = async (): Promise<AxiosResponse<any>> => {
  return await apiClient.get('/partner/partnerProfile/getAllServices');
};

export const creatPartnerProfile = async (profileDetails: FormData): Promise<AxiosResponse<any>> => {
  return await apiClient.post('/partner/partnerProfile/createPartnerProfile', profileDetails, {
  
  });
};

export const adduser = async (AddDetails: FormData): Promise<AxiosResponse<any>> => {
  return await apiClient.post('/partner/adduser', AddDetails,) 

};
export const Religions = async (): Promise<AxiosResponse<any>> => {
  return await apiClient.get('/partner/getRequiredConstants');
};
export const partneruserdetails = async (usertable:any): Promise<AxiosResponse<any>> => {
  return await apiClient.post('/partner/getpartneruserdetails',usertable);
};

export const dashboardCounts = async (): Promise<AxiosResponse<any>> => {
  return await apiClient.get('/partner/partnerRequest/viewDashboardCount');
};

export const viewBankdetails = async (partnerID:any): Promise<AxiosResponse<any>> => {
  return await apiClient.get('/partner/bankdetails/viewbankdetails?partnerID='+partnerID);
};

export const updateBankDetails = async (profileDetails:any): Promise<AxiosResponse<any>> => {
  return await apiClient.put('/partner/bankdetails/updatebankdetails',profileDetails);
};

export const viewContractFileDataPartner = async (): Promise<AxiosResponse<any>> => {
  return await apiClient.get('/partner/partnerProfile/viewPartnersContractFile');
}

export const partnerApproveContract = async (contractDetails: any): Promise<AxiosResponse<any>> => {
  return await apiClient.put('/contract/approvecontractbypartner', contractDetails);
}

export const submitInvoice = async (invoiceDetails: any): Promise<AxiosResponse<any>> => {
  return await apiClient.post('/partner/partnerRequest/generatePartnerinvoice', invoiceDetails);

}

export const adminApproveContract = async (contractDetails: any): Promise<AxiosResponse<any>> => {
  return await apiClient.put('/contract/approvecontractbypartnermanager', contractDetails);
}

// Admin Routes

export const adminDashboard = async (): Promise<AxiosResponse<any>> => {
  return await apiClient.get('/adminDashboard/viewPartnerDashboard');
}

export const adminLogin = async (credentials: any): Promise<AxiosResponse<any>> => {
  return await apiClient.post('/partner/adminlogin', credentials);
};

export const getAllPartnerDetails = async (): Promise<AxiosResponse<any>> => {
  return await apiClient.get('/partner/partnerProfile/viewAllPartnerProfile');
}
export const getAllPartnerDetailsStatus = async (status:any): Promise<AxiosResponse<any>> => {
  return await apiClient.get('/adminDashboard/partners?status='+status.status+'&pageNumber='+status.pageNumber+'&pageLimit='+status.pageLimit);
}

export const searchPartners = async (searchQuery:any): Promise<AxiosResponse<any>> => {
  return await apiClient.get(`/adminDashboard/searchbymobornames?key=${searchQuery}`);
}


export const getAllContracts = async (type : any): Promise<AxiosResponse<any>> => {
  return await apiClient.get('/contract/getallcontracts?status='+type.status+'&pageNumber='+type.pageNumber+'&pageLimit='+type.pageLimit);
}

export const getContractDetails = async (partnerID: string, contractID?: string): Promise<AxiosResponse<any>> => {
  return await apiClient.get(`/contract/getcontract?partnerID=${partnerID}&contractID=${contractID}`);
}

export const getContractDocument = async (partnerID: string): Promise<AxiosResponse<any>> => {
  return await apiClient.get('/partner/partnerProfile/viewPartnersContractFile?partnerID='+partnerID);
}

export const fetchCategories = async (): Promise<AxiosResponse<any>> => {
  return await apiClient.get('/partner/partnerRequest/viewAllCategoryDetails');
}

export const createContract = async (contractDetails: any): Promise<AxiosResponse<any>> => {
  return await apiClient.post('/contract/createcontract', contractDetails);
}

export const viewContractFileData = async (partnerID: string): Promise<AxiosResponse<any>> => {
  return await apiClient.get('/partner/partnerProfile/viewPartnersContractFile?partnerID='+partnerID.trim());
}

export const approveOrRejectPartnerDetails = async (approval: any): Promise<AxiosResponse<any>> => {
  return await apiClient.put('/partner/partnerProfile/approveOrRejectByManager', approval);
}

export const getAllPartnersContracts = async (): Promise<AxiosResponse<any>> => {
  return await apiClient.get('/contract/getcontract');
}

export const getSelectedContract = async (contractID: string): Promise<AxiosResponse<any>> => {
  return apiClient.get('/contract/viewPartnerContract?contractID='+contractID);
}

//////////////////////////////////////////////////////////////////////////////////////////////

export const updateuser = async (adduser: any): Promise<AxiosResponse<any>> => {
  return await apiClient.put('/partner/updateuser', adduser);
};

// export const viewuserdetails = async (UserID:any): Promise<AxiosResponse<any>> => {
//   return await apiClient.get('viewuserdetails'+UserID);
// };
export const viewuserdetails = async (userID: any): Promise<AxiosResponse<any>> => {

  return await apiClient.get(`/partner/viewuserdetails`, {
      params: {
          userID: userID
      }
  });
};


export const viewpendingbills = async (bills: any): Promise<AxiosResponse<any>> => {
  return await apiClient.post('/partner/partnerRequest/viewPartnerPayments', bills);
};

//  this below function to update Bills in bill section Approve / reject 
export const updatependingbills = async (bills: any): Promise<AxiosResponse<any>> => {
  return await apiClient.put('/partner/partnerRequest/updatePartnerPendingBills', bills);
};

export const newjobs = async (partnerID: any): Promise<AxiosResponse<any>> => {
  return await apiClient.get('/partner/partnerRequest/viewJobRequestDetails?PartnerID='+partnerID);
};


// below api is for view request detailes for partner 
export const viewrequestdetails = async (requestID:any): Promise<AxiosResponse<any>> => {
  return await apiClient.get('/partner/partnerRequest/viewRequest?requestID='+requestID);
};

export const acceptedrequest = async (): Promise<AxiosResponse<any>> => {
  return await apiClient.get('/partner/partnerRequest/viewacceptRequest');
};


export const regeneratOtp = async (mobilenumber: {}): Promise<AxiosResponse<any>> => {
  return await apiClient.post('/partner/regenerateOTP', mobilenumber);
};

// API call for Accept OR Reject By partner 
export const acceptRequestByPartner = async (requestDetails: {}): Promise<AxiosResponse<any>> => {
  return await apiClient.post('/partner/partnerRequest/acceptOrRejctRequest', requestDetails);
};
export const getStaffList = async (requestID:any):Promise<AxiosResponse<any>>=>{
  return await apiClient.get('/partner/partnerRequest/getStaffList?requestID='+requestID);
};
export const  submitProfile = async (profileDetails:any):Promise<AxiosResponse<any>>=>{
  return await apiClient.post('/partner/partnerRequest/submitProfile',profileDetails);
}
export const viewMSP = async ():Promise<AxiosResponse<any>>=>{
  return await apiClient.get('/adminDashboard/viewMSP');
};

export const updateMSP = async (updateMsp:any): Promise<AxiosResponse<any>> => {
  return await apiClient.put('/adminDashboard/updateMSP',updateMsp);
};

export const  viewPartnerFinanceDetails = async (financeDetails:any):Promise<AxiosResponse<any>>=>{
  return await apiClient.post('/partner/partnerRequest/viewPartnerFinanceDetails',financeDetails);
}

export const viewPartnerPendinginvoice = async (partnerID:any):Promise<AxiosResponse<any>>=>{
  return await apiClient.get('partner/partnerRequest/viewPartnerPendinginvoice?partnerID='+partnerID);
};

export const  forgetPasswoard = async (mobile:any):Promise<AxiosResponse<any>>=>{
  return await apiClient.post('/partner/forgotPassword',mobile);
}

export const  changePasswoard = async (passwordRec:any):Promise<AxiosResponse<any>>=>{
  return await apiClient.post('/partner/changePassword',passwordRec);
}


export const partnerLogin = async (credentials: any): Promise<AxiosResponse<any>> => {
  return await apiClient.post('/partner/partnerLogin', credentials);
};

export const authStore = async (auth: any): Promise<AxiosResponse<any>> => {
  return await apiClient.post('/notifications/storeauthdata', auth); 
};

export const requestsCounts = async (partnerID:any):Promise<AxiosResponse<any>>=>{
  return await apiClient.get('/partner/partnerRequest/getAllReportCount?PartnerID='+partnerID);
};

export const requestsTypes = async (partnerID:any):Promise<AxiosResponse<any>>=>{
  return await apiClient.get(`/partner/partnerRequest/getOngoingCompleteJobRequestDetails?PartnerID=${partnerID.PartnerID}&status=${partnerID.status}`);
};


export const bulkStaffUpload = async (file: any): Promise<AxiosResponse<any>> => {
  return await apiClient.post('/partner/uploadfile',file); 
};


export const adminUserDetails = async ():Promise<AxiosResponse<any>>=>{
  return await apiClient.get('/partner/adminuserdetails');
};