import React from "react";
import { Typography } from "antd";
import { IconProps } from "@tabler/icons-react";
import "./Step.css";

const { Title, Text } = Typography;

interface StepProps {
  icon: React.ComponentType<IconProps>;
  title: string;
  description: string;
  className?: string;
}

const Step: React.FC<StepProps> = ({
  icon: Icon,
  title,
  description,
  className,
  ...others
}) => {
  return (
    <div className={`feature ${className}`} {...others}>
      <div className="content">
        <Icon size={55} className="icon" stroke={1.5} />
        <Title
          level={4}
          className="title"
          style={{ marginTop: "5px", marginBottom: "8px" }}
        >
          {title}
        </Title>
        <Text type="secondary">{description}</Text>
      </div>
    </div>
  );
};

export default Step;
