import { Layout, Typography, Row, Col } from "antd";
import bannerImage from "../../assets/Anvayaa.svg";


const { Header } = Layout;
const { Text } = Typography;

const CustomHeader = () => {
  return (
    <Header
      style={{
        padding: "0 2px",
        height: "70px",
        backgroundColor: "white",
        borderBottom:"1px solid #e9ecef",
        position: 'sticky', 
        top: 0,
         zIndex: 3,
          width: '100%'
           }}
    >
      <Row align="middle">
        <Col xs={4} sm={2} >
          <img
            src={bannerImage}
            alt="Banner"
            style={{ width: "80px", height: "70px"}}
          />
        </Col>
        <Col xs={48} sm={20} style={{ textAlign: 'center' }}>
          <Text
            strong
            style={{
              fontSize: "24px",
            }}
          >
            Partner Management
          </Text>
        </Col>
      </Row>
    </Header>
  );
};

export default CustomHeader;
